body {
  font-family: 'Montserrat', sans-serif !important;
}

::-webkit-scrollbar { display: none; }

body::-webkit-scrollbar { display: none; }

#root {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
}

.mapbtn,
.mobile-menu,
.filter,
.BtnRetour,
.galleryButton {
  font-family: 'Montserrat', sans-serif;
}

.mapbtn .GroupBtn {
  height: auto;
  border-radius: 5px;
  overflow: hidden;
}

.galleryButton {
  align-content: flex-end;
}

.galleryButton a {
  text-transform: uppercase;
  font-size: .85rem;
  display: flex;
  align-items: center;
}

.galleryButton a span {
  transform: translateX(0);
  transition: transform .3s;
  font-weight: 600;
}

.galleryButton a:hover span {
  transform: translateX(3px);
}

.galleryButton a:hover {
  cursor: pointer;
}

.galleryButton a img {
  max-width: 24px;
  margin-right: 3px;
}

.accordion-button:not(.collapsed)::after {
  filter: grayscale(1) !important;
}

.placesTools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.App {
  text-align: center;
}

.GroupBtn {
  height: 5vh;
}

.logo {
  position: fixed;
  border-radius: 0;
  height: 90px;
  width: auto;
  left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.extend {
  height: 100vh;
}

.media-header {
  width: 100%;
  height: auto;
}

.separation {
  min-height: 100vh;
  border-left: 2px solid #000000;
}

.mobile-bottom-nav .btn-outline-dark:hover {
  background-color: #fff !important;
  border-radius: 0 !important;
  border-color: #e7e7e7 !important;
}

.close {
  width: 1.3rem;
  opacity: 1;
  z-index: 9;
  pointer-events: auto;
  cursor: pointer;
  float: right;
  height: auto;
  position: fixed;
  right: 30px;
  -webkit-transition: transform .3s ease-in-out;
  -moz-transition: transform .3s ease-in-out;
  -ms-transition: transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  top: 30px;
}

.closeMobile {
  filter: invert(1);
  width: 36px;
  height: 36px;
  opacity: 1;
  z-index: 9;
  pointer-events: auto;
  cursor: pointer;
  position: fixed;
  right: 25px;
  -webkit-transition: transform .3s ease-in-out;
  -moz-transition: transform .3s ease-in-out;
  -ms-transition: transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  top: 20px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close:hover {
  transform: rotate(90deg);
}

.closeExtend {
  filter: invert(100%);
  width: 1.3rem;
  opacity: 1;
  z-index: 9;
  pointer-events: auto;
  cursor: pointer;
  float: right;
  height: auto;
  position: fixed;
  right: 30px;
  -webkit-transition: transform .3s ease-in-out;
  -moz-transition: transform .3s ease-in-out;
  -ms-transition: transform .3s ease-in-out;
  -o-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  top: 30px;

}

.closeExtend:hover {
  fill: white;
  transform: rotate(90deg);
}

.mapbtn {
  left: 3vh;
  bottom: 3vh;
  position: absolute;
  border-radius: 0;
  text-transform: uppercase;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.vrlink {
  text-decoration: none;
  color: black;
}

.vrlink:hover {
  color: black;
}

.vrlink span {
  text-transform: uppercase;
  font-size: .85rem;
  vertical-align: middle;
  margin-left: .3rem;
  transition: .5s padding;
  font-weight: 600;
}

.vrlink:hover span {
  padding-left: 3px;
}

.map {
  display: flex;
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;
  background-color: #FFFFFF;
}

#map-vector {
  width: 100%;
  height: 100%;
}

#map-vector #LEGENDES g {
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s;
}

#map-vector #LEGENDES g.visible {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

#map-vector #LEGENDES g.visible circle.selected {
  fill: transparent;
  stroke-width: 7px;
  stroke: #ffc787;
}

@media (max-width: 992px) {
  #map-vector #LEGENDES g.selected circle {
    fill: transparent;
    stroke-width: 10px;
    stroke: #ffc787;
  }
}

#map-vector #LEGENDES g.selected {
  fill: transparent;
  stroke-width: 7px;
  stroke: #ffc787;
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

#LEGENDES g circle {
  fill: #ffc787;
  stroke: #ffc288;
  stroke-width: 3px;
}

#LEGENDES g:hover circle {
  stroke-width: 9px;
  stroke: #ffc787;
}

#LEGENDES g circle.hovered {
  fill: #ffc787;
  stroke: #ffc787;
  stroke-width: 9px;
}

#BOUSSOLE {
  opacity: 0;
}

#PYTHON {
  opacity: 1;
}

#FOND {
  height: 100%;
}

.accordion-div {
  border: 2px solid black;
  border-bottom: 0px solid black;
}

.accordion-div:focus {
  background-color: white;
  outline: 0;
}

.collapse-btn {
  width: 100%;
}

.collapse {
  border: 2px solid black;
}

.collapse-head {
  border-bottom: 2px solid black;
  outline: none !important;
}

.collapse-head:focus {
  outline: none;
}

.collapse-head button {
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.8rem 0.8rem;
  font-size: .9rem;
}

.collapse-bot {
  border-bottom: 2px solid black;
}

.gap-2 {
  gap: 0.5rem !important;
  text-align: left !important;
}

.card-mobile {
  display: flex;
}

.columns-card-spaces {
  column-count: 2;
  width: 100%;
  column-gap: 30px;
  padding: 20px;
}

.columns-card-spaces .card-place {
  padding: 1rem 0;
}

.columns-card-spaces .cards-img-desktop {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  object-fit: cover;
  padding: 1rem 0;
}

.columns-card-Expe {
  column-count: 1;
  padding: 20px;
  width: 100%;
  column-gap: 30px;
  padding-bottom: 5vh;
}

.columns-card-Expe .cards-img-desktop {
  max-width: 50%;
  min-width: 50%;
  height: auto;
  object-fit: cover;
  padding: 1rem 0;
}

.columns-card-Expe .card-place-desktop {
  display: flex;
  align-items: center;
}

.columns-card-Expe > .card-place > .card-place-desktop > .cards-img-desktop {
  padding-right: 1rem;
}

.list-card > .card-place-mobile {
  background-color: #FFFFFF;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 70vw !important;
  }
}

.extendFromGallery {
  display: block;
  height: calc(100vh - 80px);
  object-fit: cover;
}

@media (width < 1200px) {
  .columns-card-spaces {
    column-count: 1;
    padding: 20px;
    width: 100%;
    column-gap: 30px;
    padding-bottom: 7vh;
  }

  .columns-card-spaces .card-place-desktop {
    display: flex;
    align-items: center;
  }
  
  .columns-card-spaces > .card-place > .card-place-desktop > .cards-img-desktop {
    padding-right: 1rem;
  }

  .columns-card-spaces .cards-img-desktop {
    max-width: 50%;
    min-width: 50%;
    height: auto;
    object-fit: cover;
    padding: 1rem 0;
  }

}

@media (min-width: 992px) {
  .mobile-bottom-nav {
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;
    opacity: 0;
    display: none;
    visibility: hidden;
    pointer-events: none;
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .mobile-bottom-nav {
    display: block;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    z-index: 11;
    transform: translateY(0);
    transition: transform .3s, opacity .3s;
    left: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
    padding: 0!important;
  }

  .mobile-bottom-nav button {
    height: 58px;
    width: 100%;
    text-align: start;
    border: 1px solid #e7e7e7 !important;
  }

  .mobile-button-nav {
    height: 59px;
    width: 192px;
  }

  .extendFromGallery {
    width: 100% !important;
    height: calc(50vh - 100px);
  }
}

.card-place {
  pointer-events: auto;
  cursor: pointer;
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
}

@keyframes fadein {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.card-place {
  opacity: 0;
  animation-name: fadein;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  background: #fff;
  border-radius: 10px;
  /* box-shadow: 0 0 4px #0B0B0B14 */
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: 0 0 0 0.25rem transparent !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important;
}

.loader {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 1;
  z-index: 2000;
  left: 0;
}

.loader img {
  max-width: 90px;
}

.imgExtendMobile {
  min-height: 100vh;
  padding-bottom: 15vh;
  padding-left: 0;
  overflow: hidden!important;
}
.imgExtendDesktop {
  min-height: 100vh;
  padding-bottom: 20vh;
}

.imgExtend h5 {
  font-weight: 300 !important;
  font-size: 1.2rem;
  padding: .5rem 1rem 1rem;
}


.extendedBody {
  padding-top: 3vh;
  background-color: white;
}

@media (max-width: 992px) {
  .extendedBody {
    border-top: 1px solid #c0c0c0;
    margin-top: 33vh!important;
    padding: 30px!important;
    background-color: #FFFFFF;
  }
  .aside {
    overflow: hidden;
  }
}

@media (min-width: 992px) {
  .aside {
    overflow: hidden;
  }
}
  

.aside h1 {
  padding-right: 50px !important;
  font-size: 2.2rem;
}


.VideoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  width: auto;
  margin-bottom: 2rem;
}

.MediaWrapper {
  position: relative;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.MediaWrapper > img {
  width: 100%;
}


@media (min-width: 992px) {
  .MediaWrapper > img {
  height: 40vh;
  }
}

.embedly-embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}

@media (min-width: 992px) {
  .aside-panel {
    min-height: 100vh;
    position: relative;
    top: 0px;
    left: 0px;
    padding: 0 20px;
  }
}

@media (max-width: 992px) {

  .imgExtend h5 {
    font-size: 1rem;
  }

  .aside-panel {
    max-height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: 58px !important;
    overflow: hidden!important;
  }
}

@media (max-width: 992px) {
  .mobile-visible {
    background-color: white;
    animation-name: slideIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  .mobile-hidden {
    background-color: white;
    animation-name: slideOut;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }

  .aside h1 {
    padding-right: 0 !important;
    font-size: 1.8rem;
  }
}

.extended-mobiles {
  z-index: 10;
}

@keyframes slideIn {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100vh);
  }
}

.btn span {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  transition: color .5s;
  width: 100%;
  justify-content: center;
  padding: 0.315rem 0.5rem;
  font-size: .9rem;
  font-weight: 600;
}

.btn-outline-dark {
  background-color: #fff !important;
  border-radius: 0 !important;
  border: 2px solid !important;
  box-shadow: none !important;
}

.btn-outline-dark:hover {
  background-color: #000 !important;
  border-radius: 0 !important;
  border-color: #000 !important;
}

.btn-dark {
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: #000 !important;
}

.btn-dark:focus {
  box-shadow: none !important;

}



.cards-img {
  max-width: 50%;
  min-width: 50%;
  height: 140px;
  object-fit: cover;
  padding: 1rem;
}

.mobile-body {
  border: none;
  display: flex;
  align-items: center !important;
  margin: 0;
  padding-right: 1rem;
}

body {
  overflow: hidden;
}

.whiteMap img {
  filter: invert(1);
}

.whitePanel img {
  filter: invert(1);
}

.filter {
  text-align: right;
  font-weight: 600;
  font-size: 1.2rem;
}

.top-nav {
  left: 0;
  width: 100%;
  height: 58px;
  align-items: center;
  text-align: center;
}

.mobile-top-nav {
  background-color: #000;
  color: #FFFFFF;
  padding: 0 24px !important;
}


.menu-trigger-content>.menu-icon {
  position: relative;
  width: 28px;
  height: 2px;
  background-color: #FFFFFF;
  z-index: 10;
}

.menu-trigger-content>.menu-icon::after {
  position: absolute;
  left: 0;
  top: 9px;
  content: "";
  display: block;
  width: 28px;
  height: 2px;
  background-color: #fff;
  transition: transform .3s, top .3s;
}

.menu-trigger-content>.menu-icon::before {
  position: absolute;
  left: 0;
  top: -9px;
  content: "";
  display: block;
  width: 28px;
  height: 2px;
  background-color: #fff;
  transition: transform .3s, top .3s;
}

.menu-trigger-content>.active {
  z-index: 13;
  background-color: transparent;
}

.menu-trigger-content>.active::before {
  background-color: #000000;
  transform: rotate(45deg);
  top: 0;
  transition: top .3s, transform .3s;
}

.menu-trigger-content>.active::after {
  background-color: #000000;
  transform: rotate(-45deg);
  top: 0;
  transition: top .3s, transform .3s;
}

.mobile-menu {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background: #fff;
  transition: opacity .5s, transform .5s;
  padding: 2rem;
  text-align: center;
  transform: translateY(-210vh);
  left: 0;
}

.mobile-menu.opened {
  z-index: 12;
  transform: translateY(0);
}

.list-card {
  animation-name: fadingIn;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-direction: alternate;
  overflow: hidden;
  background-color: #FFFFFF;
}


@media (max-width: 992px) {
  .list-card {
    margin-bottom: 10vh;
    margin-top: 42vh;  
  }
}

@media screen and (max-height: 920px) {
  .list-card {
    margin-bottom: 20vh;
  }
}

.mobile-top-nav img {
  max-width: 35px;
}

.mobile-menu .logo-mobile img {
  width: 60%;
  margin-top: 10vh;
  margin-bottom: 3vh;
  vertical-align: middle;
  text-align: center;
}

.top-nav .logo-mobile {
  text-align: start;
}

.mobile-menu .menu {
  list-style: none;
  margin: 3vh 0;
  padding: 0;
}

.mobile-menu .menu li {
  font-size: 1.5rem;
  padding: 1rem 0;
}

.mobile-menu .menu li.active {
  font-weight: 700;
}

.mobile-menu .menu li::after {
  content: "";
  width: 40vw;
  height: 1px;
  background-color: #e7e7e7;
  display: block;
  margin-top: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

@keyframes fadingIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.simplebar-track {
  z-index: 1 !important;
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 11px !important;
  pointer-events: none !important;
}

.simplebar-track.simplebar-vertical {
  top: 15vh !important;
  bottom: 15vh !important;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px;
  max-height: 30vh;
}
.simplebar-scrollbar::-webkit-scrollbar { display: none; }

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background-color: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar:before::-webkit-scrollbar { display: none; }

.simplebar-track .simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible::before::-webkit-scrollbar { display: none; }

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

@media (min-width: 992px) {
  .mySwiper2 .swiper-slide {
    height: 40vh;
    width: 100%;
    margin-bottom: 12px;
  }
}
  
@media (max-width: 992px) {
  .mySwiper2 .swiper-slide {
    height: 30vh;
    width: 100%;
    margin-bottom: 12px;
  }
}
  

.mySwiper2 img {
  object-fit: cover;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}


.mySwiper .swiper-slide {
  width: 25%;
  opacity: 0.4;
  height: 60px;
}
@media (max-width: 992px) {
  .mySwiper .swiper-slide {
    width: 25%;
    opacity: 0.4;
    height: 40px;
  }
}


.mySwiper img {
  object-fit: cover;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper .swiper-slide img,
.mySwiper2 .swiper-slide img,
.mySwiperExtend .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btnExtend {
  z-index: 100;
  position: absolute;
  bottom: 25px;
  right: 30px;
  border: transparent !important;
  background-color: transparent;
  width: 1vw;
}
@media (max-width: 992px) {
  .btnExtend {
    z-index: 100;
    position: absolute;
    bottom: 15px;
    right: 0px;
    border: transparent !important;
    background-color: black;
    border-radius: 100%;
    width: 5vh;
  }  
}

.btnExtend:active {
  border: transparent;
}

.btnExtend img {
  width: 25px;
}

@media (max-width: 992px) {
  .mySwiperExtend2 {
    height: 93%;
    width: 100%;
  }
}
@media (min-width:992px) {
  .mySwiperExtend2 {
    height: 89%;
    width: 100%;
    margin-bottom: 5px;
  }
}

.mySwiperExtend2 .swiper-slide {
  background-color: #000;
}

.mySwiperExtend2 img {
  object-fit: cover;
}

.mySwiperExtend {
  height: 10%;
  box-sizing: border-box;
  padding: 10px 0;
}

@media (max-width: 992px) {
  .mySwiperExtend {
    height: 7%;
    box-sizing: border-box;
    padding: 10px 0;
  }
}



.mySwiperExtend .swiper-slide {
  width: 25%;
  opacity: 0.4;
  height: auto;
}

.mySwiperExtend img {
  object-fit: cover;
}

.mySwiperExtend .swiper-slide-thumb-active {
  opacity: 1;
}

.modal-body {
background-color: #000;
}

@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.must-rotate {
  left: 0px;
  background-color: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.must-rotate p {
  color: #fff;
  font-size: 1.3rem;
  margin: 1rem 0;
}

.must-rotate img {
  max-width: 12%;
  animation: rotate 3s infinite;
}

@media (max-width: 940px) {
  @media (orientation: landscape) {
    .must-rotate {
      visibility: visible;
      opacity: 1;
      z-index: 999999;

    }
  }
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  pointer-events: auto!important;
}

.tooltips {
  pointer-events: none;
  display: flex;
  position: absolute;
  z-index: 2;
  justify-content: center;
  width: 100%;
  bottom: 130px;
}

.OverlayGallery {
  background-color: black;
    opacity: 0.9;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
}

.complementsInfo {
  pointer-events: none;
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: #f7f5ec;
  opacity: 1;
  padding: 10px 15px;
  bottom: 0;
  right: 0;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
}
.complementsInfoExtend {
  pointer-events: none;
  background-color: #f7f5ec;
  opacity: 1;
  padding: 10px 15px;
  position: relative;
  bottom: 47px;
  font-weight: 600;
}

.OverlayGallery > svg {
  margin-right: 5px;
}


.mapMobile {
  display: initial;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  position: fixed;
  z-index: -1;
  padding: 0!important;
}

.container {
  max-width: none;
}

.subtitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 0.5rem;
}

.list-card-enter .card-place-mobile {
  opacity: 0;
}

.list-card-enter-active .card-place-mobile {
  opacity: 1;
  transition: opacity 400ms;
}

.list-card-enter-done .card-place-mobile {
  opacity: 1;
}

.list-card-exit .card-place-mobile {
  opacity: 1;
}

.list-card-exit-active .card-place-mobile {
  opacity: 0;
  transition: opacity 400ms;
}

.list-card-exit-done .card-place-mobile {
  opacity: 0;
}

.list-card-enter g#Layer {
  opacity: 0;
}

.list-card-enter-active g#Layer {
  opacity: 1;
  transition: opacity 1100ms;
}

.list-card-enter-done g#Layer {
  opacity: 1;
}

.list-card-exit g#Layer {
  opacity: 1;
}

.list-card-exit-active g#Layer {
  opacity: 0;
  transition: opacity 1100ms;
}

.list-card-exit-done  g#Layer {
  opacity: 0;
}

.list-card-enter div.mobile-anim {
  opacity: 0;
}

.list-card-enter-active div.mobile-anim {
  opacity: 1;
  transition: opacity 400ms;
}

.list-card-enter-done div.mobile-anim {
  opacity: 1;
}

.list-card-exit div.mobile-anim {
  opacity: 1;
}

.list-card-exit-active div.mobile-anim {
  opacity: 0;
  transition: opacity 400ms;
}

.list-card-exit-done  div.mobile-anim {
  opacity: 0;
}

.list-card-enter div#List-places {
  opacity: 0;
}

.list-card-enter-active div#List-places {
  opacity: 1;
  transition: opacity 400ms;
}

.list-card-enter-done div#List-places {
  opacity: 1;
}

.list-card-exit div#List-places {
  opacity: 1;
}

.list-card-exit-active div#List-places {
  opacity: 0;
  transition: opacity 400ms;
}

.list-card-exit-done  div#List-places {
  opacity: 0;
}

.list-card-enter div.aside-panel {
  opacity: 0;
}

.list-card-enter-active div.aside-panel {
  opacity: 1;
  transition: opacity 400ms;
}

.list-card-enter-done div.aside-panel {
  opacity: 1;
}

.list-card-exit div.aside-panel {
  opacity: 1;
}

.list-card-exit-active div.aside-panel {
  opacity: 0;
  transition: opacity 500ms;
}

.list-card-exit-done  div.aside-panel {
  opacity: 0;
}